$fa-font-path: "../node_modules/@fortawesome/fontawesome-free/webfonts";

@import "../node_modules/@fortawesome/fontawesome-free/scss/fontawesome.scss";
@import "../node_modules/@fortawesome/fontawesome-free/scss/regular.scss";
@import "../node_modules/@fortawesome/fontawesome-free/scss/solid.scss";
@import "../node_modules/@fortawesome/fontawesome-free/scss/brands.scss";

$default_color: #D0C8C1;
$default_hover: #99928c;
$highlight_color: #97005E;
$highlight_hover: #8c0045;

$screen-sm-max: 576px;
$screen-lg: 992px;

@import "styles/swiper/pagination";
@import "styles/swiper/slider";
