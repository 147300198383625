.swiper-slideshow {
  display: flex; // flex-wrap: nowrap;
  overflow-x: scroll;
  overflow: hidden;
  position: relative;

  margin-left: 0;
  margin-right: 0;

  .swiper-slide {
    padding: 0;
  }

  .swiper-slideshow__slider {
    display: flex;
    width: 100%;
  }

  .gallery__enlarge {
    width: 100%;
  }

  .img--full {
    width: 100%;
  }
}

.swiper-slideshow__info .swiper-pagination-bullet-active {
  background: $highlight_color;
}

.swiper-pagination-progressbar.swiper-slideshow__info {
  background: rgba(255, 255, 255, 0.5);
}

.swiper-pagination-progressbar.swiper-slideshow__info .swiper-pagination-progressbar-fill {
  background: $highlight_color;
}


.swiper-slideshow__arrow {

  &:focus {
    outline: none;
  }

  background: none;
  position: absolute;
  top: 0;
  width: 12vw;
  height: 100%;
  right: 0;
  z-index: 2;
  cursor: pointer;

  @media screen and (max-width: $screen-sm-max) {
    width: 2em;
  }

  @media screen and (min-width: $screen-lg) {
    width: 7vw;
  }

  &:hover {
    background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.2) 100%);
  }

  &:after {
    content: "";
    width: 2em;
    height: 4em;
    background: none;
    background-repeat: no-repeat;
    background-position: right;
    top: calc(50% - 2em);
    right: 1.2em;
    position: absolute;

    @media screen and (max-width: $screen-sm-max) {
      right: 0.4em;
      top: calc(50% - 1.5em);
      width: 1.5em;
      height: 3em;
    }
  }

  &:hover:after {
    background-image:url("data:image/svg+xml,%3Csvg viewBox='0 0 37 88' xmlns='http://www.w3.org/2000/svg' fill-rule='evenodd' clip-rule='evenodd' stroke-linejoin='round' stroke-miterlimit='1.414'%3E%3Cpath d='M36.013 46.532L10.287 87.889l-7.642-4.754 22.8-36.654L0 4.68 7.688 0l28.325 46.532z' fill='#{$highlight_color}' fill-rule='nonzero'/%3E%3C/svg%3E");
  }
}

.swiper-slideshow__arrow--prev {
  transform: scaleX(-1);
  left: 0;
}

.swiper-slideshow__arrow--disabled {
  display: none;
}
